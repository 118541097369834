<bit-layout variant="secondary">
  <nav slot="sidebar" *ngIf="provider$ | async as provider">
    <a routerLink="." class="tw-m-5 tw-mt-7 tw-block" [appA11yTitle]="'providerPortal' | i18n">
      <bit-icon [icon]="logo"></bit-icon>
    </a>

    <bit-nav-item
      icon="bwi-bank"
      [text]="'clients' | i18n"
      [route]="(canAccessBilling$ | async) ? 'manage-client-organizations' : 'clients'"
    ></bit-nav-item>
    <bit-nav-group
      icon="bwi-sliders"
      [text]="'manage' | i18n"
      route="manage"
      *ngIf="showManageTab(provider)"
    >
      <bit-nav-item
        [text]="'people' | i18n"
        route="manage/people"
        *ngIf="provider.canManageUsers"
      ></bit-nav-item>
      <bit-nav-item
        [text]="'eventLogs' | i18n"
        route="manage/events"
        *ngIf="provider.useEvents"
      ></bit-nav-item>
    </bit-nav-group>
    <bit-nav-group
      icon="bwi-billing"
      [text]="'billing' | i18n"
      route="billing"
      *ngIf="canAccessBilling$ | async"
    >
      <bit-nav-item [text]="'subscription' | i18n" route="billing/subscription"></bit-nav-item>
    </bit-nav-group>
    <bit-nav-item
      icon="bwi-cogs"
      [text]="'settings' | i18n"
      route="settings"
      *ngIf="showSettingsTab(provider)"
    ></bit-nav-item>
    <app-toggle-width></app-toggle-width>
  </nav>
  <app-payment-method-warnings
    *ngIf="showPaymentMethodWarningBanners$ | async"
  ></app-payment-method-warnings>
  <router-outlet></router-outlet>
</bit-layout>
